<template>
  <land-section
    id="home_activities"
    space="80"
  >
    <land-section-heading
      v-if="header.title"
      :title="header.title"
    >
      {{ header.subtitle }}
    </land-section-heading>
    <v-container
      style="max-width: 1200px;"
    >
      <v-row
        no-gutters
        justify="center"
      >
        <v-col cols="12">
          <land-gallery
            :items="items"
            :ratio="ratio"
            back-color="grey lighten-5"
            @click="onActivity"
          />
        </v-col>
      </v-row>
    </v-container>
    <div class="d-flex flex-column align-center">
      <v-btn
        v-if="button.text"
        depressed
        :color="button.backColor || 'primary'"
        :class="[button.foreColor ? `${button.foreColor}--text` : 'white--text', 'mt-5']"
        width="120"
        @click="onMore"
      >
        {{ button.text }}
      </v-btn>
    </div>
  </land-section>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'

  import mixPreset from '@/pages/mixins/mix.preset'
  const parts = mixPreset.Parts
  const names = mixPreset.Names
  const part = parts[names.ACTIVITY]

  export default {
    name: 'SectionHomeActivities',
    props: {},
    data () {
      return {
        header: {
          title: '众多活动就等你来！',
          subtitle: '在这里一展风采'
        },
        params: null,
        items: [],
        type: part.type,
        ratio: part.ratio,
        button: {
          text: '更多活动'
        }
      }
    },
    created () {
      this.getContents()
    },
    methods: {
      onActivity (ev) {
        const item = ev.item
        const params = {
          contentId: item.contentId,
          type: item.type,
          title: item.title
        }
        api.page.navigate(this, 'activityDetail', params)
      },
      onMore () {
        api.page.navigate(this, 'activity')
      },
      getContents () {
        const executed = function (res) {
          if (res.status) {
          }
        }

        this.params = web.activity.getParams({
          type: this.type,
          state: web.comm.States.PUBLISH,
          pageSize: '3',
          caches: this.items,
          executed
        })

        api.httpx.getItems(this.params)
      }
    }
  }
</script>
